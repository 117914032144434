.app {
  direction: ltr;
  color: darkred;
}

#app button {
  float: left;
}

#app p {
  color: blue;
  font-size: large;
}
