body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

 

/*        pagination  style      */


  
.material-icons {
   
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
.span-icon {
  display: inline-block;
  line-height: 24px;
  
}
.span-icon i {
  display: inline-flex;
   vertical-align: middle;
  color: #4b6cc9;
  width: 16px;
  height: 16px;

}

#root .pagination {
  
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0,.14), 0 2px 1px -2px rgba(0, 0, 0,.2),0 1px 5px 0 rgba(0, 0, 0,.12);
  margin: 0 20px 20px 20px;
  border: 0px solid #fff !important;
}


#root .pagination>li>a, 
#root .pagination>li>span {
  
   
  font-size: 15px;
  color: #222222;
  border: 1px solid #dedede;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 0px;
  padding-right: 0px;
  width: 50px;
  text-align: center;

  /* makes the square */
}

#root .pagination>.active>a, 
#root .pagination>.active>a:focus, 
#root .pagination>.active>a:hover, 
#root .pagination>.active>span, 
#root .pagination>.active>span:focus, 
#root .pagination>.active>span:hover {
  
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #006c64;
    border-color: #006c64;
    
}

#root .pagination>li:last-child>a, 
#root .pagination>li:last-child>span {
  
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    
}
#root .pagination>li:first-child>a, 
#root .pagination>li:first-child>span {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    
}


#root .pagination>li>a:hover, 
#root .pagination>li>span:hover {
    z-index: 2;
    color: #23527c;
    background-color: #006c64;
    border-color: #006c64;
    color: #fff;
    
}

#root .pagination>li>a:active, 
#root .pagination>li>span:active {
    z-index: 2;
    color: #23527c;
    background-color: #006c64;
    border-color: #006c64;
    color: #fff;
    
}

.comment {
  width: 400px;
  margin: 20px 40px 40px 20px;
  
}

.text-xlg {
 
   
  color: #8a8a8a;
  font-size: 34px;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  line-height: 40px;
  
}
.text-md {
  
   
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  
}

.text-md a {
  
   
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  text-decoration: underline;
  color: #4b6cc9;
}

