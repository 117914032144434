.app {
  direction: rtl;
  color: #00BCD4;
}

#app button {
  float: left;
}

#app p {
  color: darkred;
  font-size: larger;
}
